body {
  margin: 0;
  padding: 0;
}


body, html, #root {
  height: 100%;
  margin: 0;
  padding: 0;

  background-color: rgb(50, 50, 51);
}


/* ul, li{
  list-style: none;
  margin: 0;
  padding: 0
} */
.game-holder, 
.game-holder > li,
.game-row,
.game-row > li{
  list-style: none;
  margin: 0;
  padding: 0
}



.page-wrapper{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;

  height: 100%;
}

.options-container{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.type-game-holder{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  
}

footer > a, footer > a:hover {
  color: #fff;
}

footer > a:hover {
  text-decoration: none;
  font-weight: bold;
}

.form-container{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.beta{
  padding: 10px;
  color: #fff;
  text-align: center;
}

.form{
  width: 200px;
}

.form-input{
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem; 
  margin-bottom: 4px;
}

.btn-container{
  text-align: center;
}

.form button {
  width: 90%;
  margin: 4px;
}


.brand {
  width: 150px;
  height: 100px;
  white-space: nowrap;
  display: -webkit-flex;
  display: flex;
  margin: 5px;
  vertical-align: middle;
  overflow: hidden;
  cursor: pointer;

  -webkit-justify-content: center;

          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 20px;

  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  transition: all 0.5s ease;
}

.brand:hover{
  font-weight: bold;
  letter-spacing: 2px;
}

.bg-stryktipset {
  background-color: #00427a;
}

.bg-europatipset {
  background-color: #008733;
}

.game-links:hover{
  text-decoration: none;
}

.game-name{
  color: #fff;
}









/*html{
  background: url("./images/bg.jpg") no-repeat center center fixed;
  background-size: cover;
  overflow: hidden;
}*/

.app {
  
  display: grid;
  grid-template-columns: repeat(3,1fr);
  /* grid-template-rows: 1fr; */
  grid-template-areas: 
          '... download-btn ...'
          'chat-area main-surface users-area';
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  justify-items: center;
  margin-left: 10px;
  margin-right: 10px;

}

@media (max-width: 1145px) {
  .app {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-areas: 'download-btn ...'
                         'main-surface users-area'
                         'chat-area    chat-area';
    grid-row-gap: 10px;
    row-gap: 10px;
  }

  .chat-area .chat{
    width: 100%;
  }
}

@media (max-width: 720px) {
  .app {
    display: grid;
    justify-items: normal;
    margin: 0 10px 10px 10px;
  
    grid-template-columns: 1fr;
    grid-template-areas: 'users-area'
                         'main-surface'
                         'chat-area';
    grid-row-gap: 30px;
    row-gap: 30px;
  }

  .game-teams {
    margin-right: 0 !important;
    max-width: 60% !important;
  }

  .download-btn{
    display: none;
  }

}

.download-btn{
  grid-area: download-btn;
  padding: 10px 0 20px 0; 
}

.main-surface{
  grid-area: main-surface;
}

.cost-row{
  text-align: center;
}

.cost{
  color: #fff;
  font-size: 15px;
  margin: 0 auto;
  display: inline-block;
  width: 100%;
  text-align: center;
}

.parent-surface{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-column-gap: 40px;
  -webkit-column-gap: 40px;
          column-gap: 40px;
}

.game-holder{
  margin-bottom: 0;
}

.games-surface {
  justify-self: right;
}

.games-surface .game-span{
  height: 35px;
}

.games-surface .game-span span{
  display: inline-block;
  position: relative;
  top: 10%;
  opacity: 0;
  color: rgb(255,255,255);

  -webkit-animation: slide-in 1s both;
        animation: slide-in 1s both;
}


.betting-surface{
  width: 106px;
  height: 454px;

  cursor: pointer;
}


.game-row{
  clear: both;
}

.game-teams{
  color: #fff;
  margin-right: 30px;
  display:inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  
  max-width: 65%;
}


.squareHolder{
  padding: 0;
  float:right;
  list-style-type: none;
  font-size: 0;
}

.list-sq{
  display: inline-block;
  padding: 3px 2px 6px 1px;
  padding-left: 1px;
  margin-top: -6px;
}


.sq {
  border: 1px solid #999;
  font-size: 24px;
  font-weight: bold;
  height: 36px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  width: 36px;
  background-color: rgb(50, 50, 51);
  cursor: pointer;
  color: #fff;
  font-size: 17px;

  transition: all .2s ease-in-out;
  -webkit-animation: scale-down-sq 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) none;
	        animation: scale-down-sq 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) none;
}

.sq:hover{
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  background-color: rgb(50, 50, 51);
}


@-webkit-keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-700%);
            transform: translateY(-700%);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-700%);
            transform: translateY(-700%);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

@-webkit-keyframes scale-down-sq {
  0% {

    background-color: rgb(50, 50, 51);

    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  100% {

    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-down-sq {
  0% {

    background-color: rgb(50, 50, 51);

    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  100% {

    -webkit-transform: scale(1);
            transform: scale(1);
  }
}



.info-btn{
  width: 40px;
  height: 40px;
  color: #fff;
  background-color: #000;
  border-radius: 50%;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
}

/* .info-span{
  position: relative;
  top: 10px;
  right: 10px;
  font-size: 24px;
  font-weight: bold;

} */



/* .App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
} */

/* @keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
} */

.user-name {
  font-size: 13px;
  color: #fff;
  text-decoration: underline;
}

.chat-area{
  grid-area: chat-area;
  width: 100%;
}


.chat{
  width: 90%;

  -webkit-animation: fade-in 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: fade-in 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.card{
  height: 490px;
  border-radius: 15px !important;
  background-color: rgba(0,0,0,0.4) !important;
}
.contacts_body{
  padding:  0.75rem 0 !important;
  overflow-y: auto;
  white-space: nowrap;
}
.msg_card_body{
  overflow-y: auto;
}

.card-header{
  border-radius: 15px 15px 0 0 !important;
  border-bottom: 0 !important;
}
.card-footer{
  border-radius: 0 0 15px 15px !important;
  border-top: 0 !important;
}

.card-footer button {
  background-color: rgba(0,0,0,0.3);
  border: 0;
  color: #fff;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  cursor: pointer;
  border-left: 1px solid;
  border-left-color: #000;
  font-size: 14px;
}

.container{
  -webkit-align-content: center;
          align-content: center;
}
.search{
  border-radius: 15px 0 0 15px !important;
  background-color: rgba(0,0,0,0.3) !important;
  border:0 !important;
  color:white !important;
}
.search:focus{
     box-shadow:none !important;
       outline:0px !important;
}
.type_msg{
  background-color: rgba(0,0,0,0.3) !important;
  border:0 !important;
  color:white !important;
  height: 60px !important;
  overflow-y: auto;
}
  .type_msg:focus{
     box-shadow:none !important;
       outline:0px !important;
}
.attach_btn{
  border-radius: 15px 0 0 15px !important;
  background-color: rgba(0,0,0,0.3) !important;
    border:0 !important;
    color: white !important;
    cursor: pointer;
}
.send_btn{
  border-radius: 0 15px 15px 0 !important;
  background-color: rgba(0,0,0,0.3) !important;
    border:0 !important;
    color: white !important;
    cursor: pointer;
}
.search_btn{
  border-radius: 0 15px 15px 0 !important;
  background-color: rgba(0,0,0,0.3) !important;
  border:0 !important;
  color: white !important;
  cursor: pointer;
}
.contacts{
  list-style: none;
  padding: 0;
}
.contacts li{
  width: 100% !important;
  padding: 5px 10px;
  margin-bottom: 15px !important;
}
.active{
  background-color: rgba(0,0,0,0.3);
}
.user_img{
  height: 70px;
  width: 70px;
  border:1.5px solid #f5f6fa;

}
.user_img_msg{
  height: 40px;
  width: 40px;
  border:1.5px solid #f5f6fa;

}
.img_cont{
  position: relative;
  height: 70px;
  width: 70px;
}
.img_cont_msg{
  height: 40px;
  width: 40px;
}
.online_icon{
  position: absolute;
  height: 15px;
  width:15px;
  background-color: #4cd137;
  border-radius: 50%;
  bottom: 0.2em;
  right: 0.4em;
  border:1.5px solid white;
}
.offline{
  background-color: #c23616 !important;
}
.user_info{
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
}
.user_info span{
  font-size: 20px;
  color: white;
}
.user_info p{
  font-size: 10px;
  color: rgba(255,255,255,0.6);
  margin: 0;
}
.video_cam{
  margin-left: 50px;
  margin-top: 5px;
}
.video_cam span{
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin-right: 20px;
}
.msg_cotainer{
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  border-radius: 25px;
  padding: 10px;
  position: relative;
  color: #fff;
  max-width: 300px;
  overflow: hidden;
}

.msg_cotainer_send{
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  border-radius: 25px;
  padding: 10px;
  position: relative;
  color: #fff;
}

.msg_time{
  position: absolute;
  left: 0;
  bottom: -15px;
  color: rgba(255,255,255,0.5);
  font-size: 10px;
}
.msg_time_send{
  position: absolute;
  right:0;
  bottom: -15px;
  color: rgba(255,255,255,0.5);
  font-size: 10px;
}
.msg_head{
  position: relative;
}
#action_menu_btn{
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
  cursor: pointer;
  font-size: 20px;
}
.action_menu{
  z-index: 1;
  position: absolute;
  padding: 15px 0;
  background-color: rgba(0,0,0,0.5);
  color: white;
  border-radius: 15px;
  top: 30px;
  right: 15px;
  display: none;
}
.action_menu ul{
  list-style: none;
  padding: 0;
  margin: 0;
}
.action_menu ul li{
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 5px;
}
.action_menu ul li i{
  padding-right: 10px;
}
.action_menu ul li:hover{
  cursor: pointer;
  background-color: rgba(0,0,0,0.2);
}


/*Waiting for message*/
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 12px;
  padding: 0;
}
.lds-ellipsis div {
  position: absolute;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  -webkit-animation: lds-ellipsis1 0.6s infinite;
          animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  -webkit-animation: lds-ellipsis3 0.6s infinite;
          animation: lds-ellipsis3 0.6s infinite;
}
@-webkit-keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(19px, 0);
            transform: translate(19px, 0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(19px, 0);
            transform: translate(19px, 0);
  }
}


@media(max-width: 576px){
  .contacts_card{
    margin-bottom: 15px !important;
  }
}


@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}




.users-area{
  grid-area: users-area;
  justify-self: start;
}

.user-container{
  display: inline-block;
  padding: 6px;
}

.user-color-box {
  width: 25px;
  height: 25px;
  background-color: #4cd137;

  display: inline-block;
}

.user-container span {
  position: relative;
  top: -6px;

  margin-left: 10px;
  color: rgb(255,255,255);
  font-size: 14px;
}


/*Bootstrap hacks*/

div.modal.fade.show{
  padding-left: 0 !important;
}






